import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'layout',
    component: () => import('../views/layout/index.vue'),
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: 'index',
        component: () => import('../views/HomeView.vue')
      },
      {
        path: '/goodDetail',
        name: 'goodDetail',
        component: () => import('../views/goodDetail.vue')
      },
      {
        path: '/goodList',
        name: 'goodList',
        component: () => import('../views/goodList/index.vue')
      },
      {
        path: '/mainOrder',
        name: 'mainOrder',
        component: () => import('../views/mainOrder/index.vue'),
        redirect: '/user',
        children: [
          {
            path: '/user',
            name: 'user',
            component: () => import('../views/user/index.vue')
          },
          {
            path: '/orderList',
            name: 'orderList',
            component: () => import('../views/orderList/index.vue')
          }
        ]
      },
      {
        path: '/sureOrder',
        name: 'sureOrder',
        component: () => import('../views/sureOrder/index.vue')
      },
      {
        path: '/shoppingCart',
        name: 'shoppingCart',
        component: () => import('../views/shoppingCart/index.vue')
      },
    ]
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/register/index.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue'),
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
let token = localStorage.getItem('token')
router.beforeEach((to, from, next) => {
  if (to.name === 'login') {
    if (token) {
      next('/index')
    } else {
      next()
    }
  } else {
    next()
  }
})
export default router
