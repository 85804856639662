<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {
    about() {
      window.open('/about')
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
}

* {
  margin: 0;
  padding: 0;
  word-break: break-all;
}

body {
  background-color: #f4f4f4 !important;
}

.w1200 {
  width: 1200px;
  margin: 0 auto;
}

.ell {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: initial;
}
.ell2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: initial;
}

// 滚动条样式
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/*定义滚动条轨道*/
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/*定义滑块*/
::-webkit-scrollbar-thumb {
  background-color: #d3d3d3;
  border-radius: 20px;
}
.tac {
  text-align: center;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ff5730 !important;
  border-color: #ff5730 !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #ff5730 !important;
  &::after {
    background-color: #ff5730 !important;
  }
}
// .ant-pagination-item:hover {
//   border-color: #ff5730 !important;
// }
// .ant-pagination-item-active {
//   border-color: #ff5730 !important;
// }
// .ant-pagination-item-active a,
// .ant-pagination-item:hover a {
//   color: #ff5730 !important;
// }
</style>
